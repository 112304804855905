.logo{
    font-size:25px;
    width: 25px;
    text-decoration:none;
    color:#575757;
    font-family:Poppins;
    font-weight:600;
}
.header-player{
    margin-left:5px;
    font-family:Poppins;
    font-size:15px;
    font-weight:600;
}
@media screen and (max-width:915px) {
    .header-player{
        font-size: 12px;
    }
    .logo{
        font-size:18px;
        width: 18px;
    }
}