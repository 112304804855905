.message-inner-box{
    height: 79vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding-bottom: 6px;
}

.message-inner-box::-webkit-scrollbar {
    width: 6px;
}

.message-inner-box::-webkit-scrollbar-track {
    display: none;
}

.message-inner-box::-webkit-scrollbar-thumb {
    background-color: rgb(124, 127, 129);
    border-radius: 100px;   
}

#messageBox-container{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.message-input{
    width: 90%;
    max-height: 300px;
    height: auto;
    padding: 14px;
    border: none;
    border-radius: 5px;
    outline: none;
    resize: none;
    background-color:#f2f0eb;
    overflow: auto;
    font-family: Poppins;
    font-weight: 600;
}
.message-input::placeholder{
    font-weight: 500;
}

.message-input::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    overflow: hidden;
}

.message-input::-webkit-scrollbar-track {
    display: none;
}

.message-input::-webkit-scrollbar-thumb {
    background-color: rgb(173, 175, 176);
    border-radius: 100px;   
}

.outer-input{
    height: auto;
    background-color:#f2f0eb;
}

.message-input::selection{
    color: white;
    background-color: #4CAF50;
}

.message-time, .user, .player-name{
    color: white;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
}

.send-message-box{
    margin-top: 5px;
}

.send-message{
    background-color: rgb(78, 177, 81);
    align-self: flex-end;
    width: 90%;
    min-width: 140px;
    border-radius: 15px 0 15px 15px;
    padding: 6px 14px;
    margin-right: 3px;
    margin-left: 2.3rem;
}

.send-message .send-text{
    font-size: 15px;
    font-family: Poppins;
    color: white;
    margin: 0;
    padding: 0px;
}

.default-messages{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 4px;
    overflow: auto;
    white-space: nowrap;
    border-radius: 8px;
    padding: 5px 0px;
}

.default-messages::-webkit-scrollbar {
    display: none;
}

.default-messages .default-message{
    all: unset;
    margin-inline: 2px;
    background-color: rgb(242, 240, 235);
    color: black;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    border-radius: 20px;
    padding: 4px 10px;
    cursor: pointer;
}

.selected-player{
    padding: 20px;
}

.selected-player-image{
    width:70px;
    overflow: hidden;
    height:auto;
    border-radius:100px;
}
.selected-player-image img {
    width: 100% ;
    height: 100% ;
    object-fit:contain;
}
.initial-message{
    font-size: 17px;
    margin-top: 1rem;
    font-family: Poppins;
    font-weight: 600;
}
#typing-image{
    width: 70px;
}
.avatar-div{
    max-width:32px;
    overflow: hidden;
    height:32px;
    border-radius:100px;
}
.avatar{
    width:100%;
    height:100%;
    object-fit:cover
}
#bottom-space{
    width:100%;
    background-color:white;
    padding:7px;
    position:fixed;
    bottom:0;
    height:30px
}
.form{
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 30px;
}
@media screen and (max-width:1280px) {
    .message-inner-box{
        height: 68vh;
        overflow-y: auto;
        scroll-behavior: smooth;
        padding-bottom: 6px;
    }
}
@media screen and (max-width:915px) {
    .message-inner-box{
        height: 430px;
        overflow-y: auto;
        scroll-behavior: smooth;
        padding-bottom: 6px;
    }
}

@media screen and (max-width:915px) {
    .form{
        text-align: center;
        width: 100%;
        position: fixed;
        bottom: 20px;
    }
    #bottom-space{
        display: none;
    }
    .header-player{
        font-size: 12px;
    }
    .message-input{
        width: 78%;
        font-size: 14px;
    }
    .outer-input{
        width: 90%;
        /* background-color: green; */
    }
    .send-message .send-text{
        font-size: 12px;
        margin: 0;
        padding: 0;
    }
    .send-message{
        min-width: 120px;
        margin-left: 1.9rem;
    }
   
    #chat-box{
        width: 90%;
    }
    .selected-player{
        padding: 15px;
    }
    .initial-message{
        font-size: 12px;
        margin-top: 0.5rem;
        text-align: center;
    }
    .selected-player-image{
        width:50px;
        overflow: hidden;
        height:auto;
        border-radius: 100px;
    }
    .default-messages .default-message{
        font-size: 10px;
        padding: 3px 7px;
    }
    #typing-image{
        width: 50px;
        margin-top: 5px;
    }
    .message-time, .user, .player-name{
        font-size: 10px;
    }   
    .avatar-div{
        max-width:25px;
        overflow: hidden;
        height:25px;
        border-radius:100px;
    }
    .avatar{
        width:100%;
        height:100%;
        object-fit:cover
    }
}
@media screen and (min-width:915px) {
    .outer-input{
        /* background-color: yellow; */
        width: 60%;
    }
    .message-input{
        width: 90%;
        font-size: 14px;
    }
   
    .message-inner-box{
        width: 60%;
    }
}

