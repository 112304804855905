.receive-message-box{
    margin-top: 5px;
}
.received-message{
    background-color: #575757;
    align-self: flex-start;
    width: 89%;
    border-radius: 0px 15px 15px 15px;
    margin-left: 6px;
    padding: 6px 14px;
    min-width: 140px;
}

.received-message #received-text{
    font-size: 15px;
    font-family: Poppins;
    color: white; 
    margin: 0;
    padding: 0;
}
.user-image-div{
    max-width:32px;
    overflow: hidden;
    height:32px;
    border-radius:100px;
}
.user-image{
    width:100%;
    height:100%;
    object-fit:cover
}

@media screen and (max-width:915px) {
    .received-message{
        min-width: 180px;
        width: 80%;
        /* margin-top: 5px; */
    }
    .received-message #received-text{
        font-size: 12px;
        margin: 0;
        padding: 0;
    }
    .user-image-div{
        max-width:25px;
        overflow: hidden;
        height:25px;
        border-radius:100px;
    }
    .user-image{
        width:100%;
        height:100%;
        object-fit:cover
    }
}