.characters{
    border-radius: 6px; 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    width:190px;
    background-color: #f2f0eb;
    text-decoration: none;
    color: black;
}
.characters:hover{
    transform: scale(1.1);
    transition: 0.4s ease-in-out;
}
.player-img{
    max-width: 140px;
    overflow: hidden;
    height: 140px;
    border-radius: 100px;
}
.player-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.search-input{
    width: 50%;
    border-radius: 5px;
    padding: 14px;
    border: none;
    outline: none;
    font-family: Poppins;
    background-color:#f2f0eb;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.search-input:focus{
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.search-bar p{
    font-family:Poppins;
    margin-top:1rem;
    color: rgb(99, 98, 98);
    font-size:20px;
}
.search-bar h2{
    font-family:Poppins;
    text-align:center;
    margin-top:2rem;
    font-weight:600;
    font-size:35px;
}
.playerName{
    font-family:Poppins;
    font-size:18px;
    font-weight:600;
    width:160px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


@media screen and (max-width:915px) {
    .characters{
        width:145px;
        height: 160px;
    }
    .player-img{
        max-width: 80px;
        overflow: hidden;
        height: 80px;
    }
    .search-bar h2{
        font-size:22px;
    }
    .search-bar p{
        font-size:18px;
    }
    .playerName{
        font-family:Poppins;
        font-size:16px;
        font-weight:600;
        width:125px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .search-input{
        width: 80%;
        padding: 12px;
    }
}